import type { FC } from 'react';
import { NavLink } from 'react-router-dom';
import Tooltip from '@components/interface/Tooltip';
import { Icon } from '@iconify/react';

type NavBarButtonProps = {
  id: string;
  to: string;
  icon: string;
  isExpanded: boolean;
  label: string;
  notIncludedOnCurrentPlan?: boolean;
};

const NavBarButton: FC<NavBarButtonProps> = ({ to, id, icon, isExpanded, label, notIncludedOnCurrentPlan = false }) => {
  return (
    <Tooltip label={label} enabled={!isExpanded}>
      <NavLink
        to={notIncludedOnCurrentPlan ? `suscripcion/${to}` : to}
        className={({ isActive }) =>
          `relative flex flex-1 lg:flex-auto flex-col px-2 rounded-b-md items-center lg:hover:bg-color-gray_light hover:text-color-primary lg:flex-row lg:py-2 lg:rounded-md lg:pr-0 after:w-full after:h-2 after:rounded-full lg:after:w-3 lg:after:h-full after:mt-[3px] ${
            isActive
              ? 'text-color-primary bg-color-white  lg:bg-color-gray_light after:bg-color-secondary'
              : 'pr-3 text-color-gray_ultra_dark lg:text-color-primary'
          }`
        }
        id={id}
      >
        <img src={icon} alt="navegacion" className={`transition-[width] m-auto ${isExpanded ? 'w-5' : 'w-6'}`} />
        <div className={`lg:overflow-hidden lg:transition-[width] ${isExpanded ? 'lg:w-full' : 'lg:w-0'}`}>
          <span className="px-2 lg:w-full lg:whitespace-nowrap">{label}</span>
        </div>
        {notIncludedOnCurrentPlan && (
          <div className="absolute right-1">
            <Icon icon="solar:tag-price-bold" color="#FF3939" width="18px" height="18px" />
          </div>
        )}
      </NavLink>
    </Tooltip>
  );
};

export default NavBarButton;
