import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import type { useAuth } from '@context/AuthContext';
import LoadingPage from '@pages/LoadingPage';
import { onBoardingRoutes } from './onBoardingRoutes';
import { unauthenticatedRoutes } from './unauthenticatedRoutes';
import Layout from '@components/layout/Layout';
import { useSubscription } from '@pages/Subscriptionsv2/hooks/useSubscriptions';
import useModules from '@utils/hooks/useModules';
import { lazy, Suspense } from 'react';

const CanceledDeliveryByProviderModal = lazy(() => import('@pages/Orders/components/CanceledDeliveryByProviderModal'));
const CancelDeliveryModal = lazy(() => import('@pages/Orders/components/CancelDeliveryModal'));
const RegisterOrderModal = lazy(() => import('@pages/Orders/RegisterOrderModal'));
const Orders = lazy(() => import('@pages/Orders/Orders'));
const GetModuleDescription = lazy(() => import('@pages/Subscriptionsv2/GetModuleDescription'));
const ConfirmEnableTrial = lazy(() => import('@pages/Subscriptionsv2/components/ConfirmEnableTrial'));
const AddUser = lazy(() => import('@pages/Users/AddUser'));
const Preferences = lazy(() => import('@pages/Preferences/Preferences'));
const Users = lazy(() => import('@pages/Users/Users'));
const ResendNewPassword = lazy(() => import('@pages/Users/RecoveryUserPassword'));
const AcceptanceForm = lazy(() => import('@pages/Payment/AcceptanceForm'));
const AddAdvertise = lazy(() => import('@pages/Adds/AddAdvertise'));
const AddCategory = lazy(() => import('@pages/Menu/Products/AddCategory'));
const AddExtra = lazy(() => import('@pages/Menu/Extras/AddExtra'));
const AddProduct = lazy(() => import('@pages/Menu/Products/AddProduct'));
const Adds = lazy(() => import('@pages/Adds/Adds'));
const ScheduleAds = lazy(() => import('@pages/ScheduleAds/ScheduleAds'));
const AddSections = lazy(() => import('@pages/Menu/Sections/AddSection'));
const AddTurn = lazy(() => import('@pages/Schedule/AddTurn'));
const ChangePassword = lazy(() => import('@pages/Auth/ChangePassword'));
const CommerceData = lazy(() => import('@pages/Commerce/CommerceData'));
const CommerceCategories = lazy(() => import('@pages/Commerce/modals/Categories'));
const DeliveryData = lazy(() => import('@pages/Commerce/DeliveryData'));
const Estadisticas = lazy(() => import('@pages/Stats/Estadisticas'));
const Clients = lazy(() => import('@pages/Clients/Clients'));
const OrderDetails = lazy(() => import('@pages/Orders/OrderDetails'));
const OrdersHistory = lazy(() => import('@pages/Orders/OrdersHistory'));
const PageTutorials = lazy(() => import('@pages/Tutorials/PageTutorials'));
const Schedule = lazy(() => import('@pages/Schedule/Schedule'));
const ShareMenu = lazy(() => import('@pages/ShareMenu/ShareMenu'));
const SortCategories = lazy(() => import('@pages/Menu/Products/SortCategories'));
const SortProducts = lazy(() => import('@pages/Menu/Products/SortProducts'));
const OnLineOrders = lazy(() => import('@pages/OnLineOrders/OnLineOrders'));
const Location = lazy(() => import('@pages/Commerce/modals/ChangeLocation'));
const DeliveryCostSimulator = lazy(() => import('@pages/Commerce/modals/DeliveryCostSimulator'));
const DeliveryDataConfig = lazy(() => import('@pages/Commerce/modals/DeliveryDataConfig'));
const WelcomeToFreemium = lazy(() => import('@pages/Subscriptionsv2/components/WelcomeToFreemium'));
const WelcomeToTrial = lazy(() => import('@pages/Subscriptionsv2/components/WelcomeToTrial'));
const MyAccount = lazy(() => import('@pages/Subscriptionsv2/MyAccount'));
const Coupons = lazy(() => import('@pages/Coupons/Coupons'));
const AddCoupon = lazy(() => import('@pages/Coupons/AddCoupon'));
const Integrations = lazy(() => import('@pages/Integrationsv3/Integrations'));
const Menu = lazy(() => import('@pages/Menu/Menu'));
const Products = lazy(() => import('@pages/Menu/Products/Products'));
const Extras = lazy(() => import('@pages/Menu/Extras/Extras'));
const Sections = lazy(() => import('@pages/Menu/Sections/Sections'));
const VideoEmb = lazy(() => import('@pages/Tutorials/VideoEmb'));
const IntegrationModal = lazy(() => import('@pages/Integrationsv3/components/Modal'));
const IntegrationDetail = lazy(() => import('@pages/Integrationsv3/components/IntegrationDetail'));
const IntegrationRegisterForm = lazy(() => import('@pages/Integrationsv3/components/IntegrationRegisterForm'));
const IntegrationConfig = lazy(() => import('@pages/Integrationsv3/components/IntegrationConfig'));

export const getRouter = (auth: ReturnType<typeof useAuth>) => {
  const { isFreemium, isTrial, isFounder } = useSubscription();
  const { getRootPath } = useModules();

  if (!auth.isReady) return createBrowserRouter(createRoutesFromElements(<Route path="*" element={<LoadingPage />} />));

  if (!auth.isAuthenticated) return createBrowserRouter(unauthenticatedRoutes);

  if (auth.onboardingDate === null) return createBrowserRouter(onBoardingRoutes);

  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to={getRootPath()} />} />
        <Route
          path="estadisticas"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Estadisticas />
            </Suspense>
          }
        />

        <Route
          path="menu"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Menu />
            </Suspense>
          }
        >
          <Route index element={<Navigate to="productos" />} />
          <Route
            path="productos"
            element={
              <Suspense fallback={<LoadingPage />}>
                <Products />
              </Suspense>
            }
          >
            <Route
              path="ordenar-categorias"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <SortCategories />
                </Suspense>
              }
            />
            <Route
              path="ordenar-productos"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <SortProducts />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="extras"
            element={
              <Suspense fallback={<LoadingPage />}>
                <Extras />
              </Suspense>
            }
          ></Route>
          <Route
            path="secciones"
            element={
              <Suspense fallback={<LoadingPage />}>
                <Sections />
              </Suspense>
            }
          ></Route>
          <Route path="*" element={<Navigate to="/menu" />} />
        </Route>
        <Route
          path="menu/productos/nueva-categoria"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AddCategory />
            </Suspense>
          }
        />
        <Route
          path="menu/productos/editar-categoria/:categoryId"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AddCategory />
            </Suspense>
          }
        />
        <Route
          path="menu/productos/nuevo-producto"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AddProduct />
            </Suspense>
          }
        />
        <Route
          path="menu/productos/editar-producto/:productId"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AddProduct />
            </Suspense>
          }
        />
        <Route
          path="menu/extras/nuevo-extra"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AddExtra />
            </Suspense>
          }
        />
        <Route
          path="menu/extras/editar-extra/:extraId"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AddExtra />
            </Suspense>
          }
        />
        <Route
          path="menu/secciones/nueva-seccion"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AddSections />
            </Suspense>
          }
        />
        <Route
          path="menu/secciones/editar-seccion/:sectionId"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AddSections />
            </Suspense>
          }
        />
        <Route
          path="historial-pedidos"
          element={
            <Suspense fallback={<LoadingPage />}>
              <OrdersHistory />
            </Suspense>
          }
        >
          <Route
            path="detalle/:orderId"
            element={
              <Suspense fallback={<LoadingPage />}>
                <OrderDetails />
              </Suspense>
            }
          >
            <Route
              path="cancelar-reparto"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <CancelDeliveryModal />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="control-pedidos"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Orders />
            </Suspense>
          }
        >
          <Route
            path="registrar-pedido"
            element={
              <Suspense fallback={<LoadingPage />}>
                <RegisterOrderModal />
              </Suspense>
            }
          />
          <Route
            path="reparto-cancelado/:orderId"
            element={
              <Suspense fallback={<LoadingPage />}>
                <CanceledDeliveryByProviderModal />
              </Suspense>
            }
          />
          <Route
            path="detalle/:orderId"
            element={
              <Suspense fallback={<LoadingPage />}>
                <OrderDetails />
              </Suspense>
            }
          >
            <Route
              path="cancelar-reparto"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <CancelDeliveryModal />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="pedidos-en-linea"
          element={
            <Suspense fallback={<LoadingPage />}>
              <OnLineOrders />
            </Suspense>
          }
        >
          <Route
            path="detalle/:orderId"
            element={
              <Suspense fallback={<LoadingPage />}>
                <OrderDetails />
              </Suspense>
            }
          >
            <Route
              path="cancelar-reparto"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <CancelDeliveryModal />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="clientes"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Clients />
            </Suspense>
          }
        />
        <Route
          path="anuncios"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Adds />
            </Suspense>
          }
        >
          <Route
            path="nuevo"
            element={
              <Suspense fallback={<LoadingPage />}>
                <AddAdvertise />
              </Suspense>
            }
          />
          <Route
            path="editar/:addId"
            element={
              <Suspense fallback={<LoadingPage />}>
                <AddAdvertise />
              </Suspense>
            }
          />
          <Route
            path="agendar"
            element={
              <Suspense fallback={<LoadingPage />}>
                <ScheduleAds />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="compartir-menu"
          element={
            <Suspense fallback={<LoadingPage />}>
              <ShareMenu />
            </Suspense>
          }
        />
        <Route
          path="cupones"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Coupons />
            </Suspense>
          }
        >
          <Route
            path="coupon/nuevo"
            element={
              <Suspense fallback={<LoadingPage />}>
                <AddCoupon />
              </Suspense>
            }
          />
          <Route
            path="coupon/edit/:couponId"
            element={
              <Suspense fallback={<LoadingPage />}>
                <AddCoupon />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="asistencia"
          element={
            <Suspense fallback={<LoadingPage />}>
              <PageTutorials />
            </Suspense>
          }
        >
          <Route
            path="tutorial/:tutoId"
            element={
              <Suspense fallback={<LoadingPage />}>
                <VideoEmb />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="datos-del-comercio"
          element={
            <Suspense fallback={<LoadingPage />}>
              <CommerceData />
            </Suspense>
          }
        >
          <Route
            path="cambiar-contrasena"
            element={
              <Suspense fallback={<LoadingPage />}>
                <ChangePassword />
              </Suspense>
            }
          />
          <Route
            path="categorias"
            element={
              <Suspense fallback={<LoadingPage />}>
                <CommerceCategories />
              </Suspense>
            }
          />
          <Route
            path="ubicacion"
            element={
              <Suspense fallback={<LoadingPage />}>
                <Location />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="horarios"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Schedule />
            </Suspense>
          }
        >
          <Route
            path="nuevo"
            element={
              <Suspense fallback={<LoadingPage />}>
                <AddTurn />
              </Suspense>
            }
          />
          <Route
            path="editar/:turnIndex"
            element={
              <Suspense fallback={<LoadingPage />}>
                <AddTurn />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="tarifas"
          element={
            <Suspense fallback={<LoadingPage />}>
              <DeliveryData />
            </Suspense>
          }
        >
          <Route
            path="simulador"
            element={
              <Suspense fallback={<LoadingPage />}>
                <DeliveryCostSimulator />
              </Suspense>
            }
          />
          <Route
            path="delivery-config"
            element={
              <Suspense fallback={<LoadingPage />}>
                <DeliveryDataConfig />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="configuracion-cobro-en-linea"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AcceptanceForm />
            </Suspense>
          }
        />
        <Route
          path="usuarios"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Users />
            </Suspense>
          }
        >
          <Route
            path="nuevo"
            element={
              <Suspense fallback={<LoadingPage />}>
                <AddUser />
              </Suspense>
            }
          />
          <Route
            path="editar/:userId"
            element={
              <Suspense fallback={<LoadingPage />}>
                <AddUser />
              </Suspense>
            }
          />
          <Route
            path="recuperar-contrasena/:userSelected"
            element={
              <Suspense fallback={<LoadingPage />}>
                <ResendNewPassword />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="ajustes"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Preferences />
            </Suspense>
          }
        />
        {isFreemium && (
          <>
            <Route
              path="bienvenido-freemium"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <WelcomeToFreemium />
                </Suspense>
              }
            />
            <Route
              path="suscripcion/:module"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <GetModuleDescription />
                </Suspense>
              }
            />
            <Route
              path="activar-trial"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <ConfirmEnableTrial />
                </Suspense>
              }
            />
          </>
        )}
        {isTrial && (
          <Route
            path="bienvenido-prueba-gratis"
            element={
              <Suspense fallback={<LoadingPage />}>
                <WelcomeToTrial />
              </Suspense>
            }
          />
        )}
        {!isFounder && (
          <Route
            path="mi-cuenta"
            element={
              <Suspense fallback={<LoadingPage />}>
                <MyAccount />
              </Suspense>
            }
          />
        )}
        <Route
          path="integraciones"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Integrations />
            </Suspense>
          }
        >
          <Route
            path=":providerType/:providerCode"
            element={
              <Suspense fallback={<LoadingPage />}>
                <IntegrationModal />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="integraciones/:providerType/:providerCode/detail"
          element={
            <Suspense fallback={<LoadingPage />}>
              <IntegrationDetail />
            </Suspense>
          }
        />
        <Route
          path="integraciones/:providerType/:providerCode/registro"
          element={
            <Suspense fallback={<LoadingPage />}>
              <IntegrationRegisterForm />
            </Suspense>
          }
        />
        <Route
          path="integraciones/:providerType/:providerCode/configuration"
          element={
            <Suspense fallback={<LoadingPage />}>
              <IntegrationConfig />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>,
    ),
  );
};
