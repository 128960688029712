/* eslint-disable complexity */
/* global URL_MENU */
import { FC, useRef, useState, useEffect } from 'react';
import logoPlick from '@assets/images/logo.svg';
import img_facebook from '@assets/images/facebook.svg';
import img_instagram from '@assets/images/instagram.svg';
import IconoQ from '@assets/images/icono-question.svg';
import priceIcon from '@assets/images/priceIcon.svg';
import { useAuth } from '@context/AuthContext';
import { useDesktop } from '@utils/hooks/useDesktop';
import useModules from '@utils/hooks/useModules';
import ButtonWithIconLeft from '@components/interface/ButtonWithIconLeft';
import ButtonAction from '@components/interface/ButtonAction';
import { useNavigate } from 'react-router-dom';
import { useEditCommerce, useGetCommerce } from '@pages/Commerce/hooks/commerce';
import BtnQR from '@assets/images/iconos-aside-panel-qr.svg';
import onlinePaymentIcon from '@assets/images/onlinePaymentIcon.svg';
import UsersIcon from '@assets/images/icono-mis clientes.svg';
import NavBarStatus from '../NavBar/components/interface/NavBarStatus';
import { ModuleName } from '@utils/constants';
import { useSubscription } from '@pages/Subscriptionsv2/hooks/useSubscriptions';
import { useChatwoot } from '@context/ChatwootContext';
import { OrdersCounter } from './OrdersCounter';
import { Tabler } from '@plick/ui';

const getNotIncludedOnCurrentPlanIcon = (hasModule: boolean) => {
  return hasModule
    ? null
    : {
        icon: 'solar:tag-price-bold',
        color: 'white',
        width: '20px',
        containerClassName: 'absolute right-0',
      };
};

const getNavigatePath = (hasModule: boolean, path: string) => {
  return hasModule ? path : 'suscripcion/' + path;
};

const Header: FC = () => {
  const auth = useAuth();
  const { isFreemium, isFounder, orderLimits } = useSubscription();
  const mobileMenuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);
  const isDesktop = useDesktop();
  const navigate = useNavigate();
  const { commerce } = useGetCommerce();
  const { isFetching, handleUpdate } = useEditCommerce();
  const { hasModule, modulePermissions } = useModules();
  const permissions = modulePermissions(ModuleName.HORARIOS);
  const { toggleBubbleVisibility } = useChatwoot();
  const [isNewMenuOpen, setIsNewMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (buttonRef.current && !buttonRef.current.contains(e.target as Node)) {
        setIsNewMenuOpen(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const switchOpen = () => {
    if (isOpen) {
      if (mobileMenuRef.current) {
        mobileMenuRef.current.classList.remove('translate-y-16');
        mobileMenuRef.current.classList.add('-translate-y-60');
        mobileMenuRef.current.addEventListener('transitionend', () => setOpen(false), { once: true });
      }
    } else setOpen(true);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      if (mobileMenuRef && mobileMenuRef.current) {
        if (isOpen) {
          mobileMenuRef.current.classList.remove('-translate-y-60');
          mobileMenuRef.current.classList.add('translate-y-16');
        }
      }
    });
  }, [isOpen]);

  const newMenuItems = [
    {
      label: 'Soporte en línea',
      icon: <Tabler.IconHelpSquareRounded width={22} height={22} />,
      onClick: () => toggleBubbleVisibility(),
      color: 'inherit',
    },
    {
      label: 'Ir a mi menú',
      icon: <Tabler.IconChefHat width={22} height={22} />,
      onClick: () => window.open(`${URL_MENU}/${commerce.id_path}`),
      color: 'inherit',
    },
    {
      label: 'Cerrar Sesión',
      icon: <Tabler.IconLogout width={22} height={22} />,
      onClick: auth.logout,
      color: '#FF3939',
    },
  ];

  return (
    <>
      {!isDesktop && isOpen && (
        <div
          ref={mobileMenuRef}
          className="fixed w-full -translate-y-60 bg-color-primary text-color-white px-2 transition-transform ease-out z-50"
        >
          <div className="flex text-sm justify-center">
            <span>
              <b>Usuario:</b> {auth.user.username}
            </span>
          </div>
          <div className="px-2" onClick={switchOpen}>
            <section className="px-2 justify-center flex flex-col">
              <h5 className="text-lg text-color-white lg:text-color-primary px-2 text-center line-clamp-2">
                {commerce.nombre}
              </h5>
              <NavBarStatus isOpen={commerce.servicio_activo ?? true} />
              <ButtonAction
                colorTxt="color-secondary"
                colorBg="color-white"
                hoverBg="color-gray_light"
                hasBorder
                textButton="SOPORTE EN LÍNEA"
                icon="clarity:chat-bubble-solid"
                onClick={() => toggleBubbleVisibility()}
              />
              <ButtonAction
                colorTxt="color-secondary"
                colorBg="color-white"
                hoverBg="color-gray_light"
                hasBorder
                textButton="IR A MI MENÚ"
                icon="fa-solid:hamburger"
                onClick={() => window.open(`${URL_MENU}/${commerce.id_path}`)}
              />
              {permissions.edit && (
                <ButtonAction
                  textButton={commerce.servicio_activo ? 'CERRAR TEMPORALMENTE' : 'ABRIR NEGOCIO'}
                  disable={isFetching}
                  icon={isFetching ? 'eos-icons:loading' : ''}
                  colorBg="color-white"
                  colorTxt="color-secondary"
                  hasBorder
                  hoverBg="color-gray_light"
                  onClick={() => handleUpdate({ ...commerce, servicio_activo: !commerce.servicio_activo })}
                />
              )}
            </section>
            {(isFreemium || hasModule(ModuleName.COMPARTIR)) && (
              <ButtonWithIconLeft
                fullWidth
                allowPropagation
                label="Compartir Menú"
                svgIcon={BtnQR}
                iconColor="#4298b5"
                onClick={() => {
                  navigate(getNavigatePath(hasModule(ModuleName.COMPARTIR) || false, 'compartir-menu'));
                }}
                rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.COMPARTIR) || false)}
              />
            )}
            {(isFreemium || hasModule(ModuleName.COBROS_EN_LINEA)) && (
              <ButtonWithIconLeft
                fullWidth
                allowPropagation
                label="Cobros en línea"
                svgIcon={onlinePaymentIcon}
                iconColor="#4298b5"
                onClick={() => {
                  navigate(getNavigatePath(hasModule(ModuleName.COBROS_EN_LINEA) || false, 'pedidos-en-linea'));
                }}
                rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.COBROS_EN_LINEA) || false)}
              />
            )}
            {(isFreemium || hasModule(ModuleName.DATOS_COMERCIO)) && (
              <ButtonWithIconLeft
                fullWidth
                allowPropagation
                label="Datos del comercio"
                icon="fa-solid:store"
                iconColor="#4298b5"
                onClick={() => {
                  navigate(getNavigatePath(hasModule(ModuleName.DATOS_COMERCIO) || false, 'datos-del-comercio'));
                }}
                rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.DATOS_COMERCIO) || false)}
              />
            )}
            {(isFreemium || hasModule(ModuleName.HORARIOS)) && (
              <ButtonWithIconLeft
                fullWidth
                allowPropagation
                label="Horarios"
                icon="bxs:time-five"
                iconColor="#4298b5"
                onClick={() => {
                  navigate(getNavigatePath(hasModule(ModuleName.HORARIOS) || false, 'horarios'));
                }}
                rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.HORARIOS) || false)}
              />
            )}
            {(isFreemium || hasModule(ModuleName.TARIFAS)) && (
              <ButtonWithIconLeft
                fullWidth
                allowPropagation
                label="Tarifas y entregas"
                svgIcon={priceIcon}
                iconColor="#4298b5"
                onClick={() => {
                  navigate(getNavigatePath(hasModule(ModuleName.TARIFAS) || false, 'tarifas'));
                }}
                rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.TARIFAS) || false)}
              />
            )}
            {(isFreemium || hasModule(ModuleName.CUPONES)) && (
              <ButtonWithIconLeft
                fullWidth
                allowPropagation
                label="Cupones"
                icon="mdi:coupon"
                iconColor="#4298b5"
                onClick={() => {
                  navigate(getNavigatePath(hasModule(ModuleName.CUPONES) || false, 'cupones'));
                }}
                rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.CUPONES) || false)}
              />
            )}
            {(isFreemium || hasModule(ModuleName.USUARIOS)) && (
              <ButtonWithIconLeft
                fullWidth
                allowPropagation
                label="Alta de usuarios"
                svgIcon={UsersIcon}
                iconColor="#4298b5"
                onClick={() => {
                  navigate(getNavigatePath(hasModule(ModuleName.USUARIOS) || false, 'usuarios'));
                }}
                rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.USUARIOS) || false)}
              />
            )}
            {(isFreemium || hasModule(ModuleName.ASISTENCIA_TECNICA)) && (
              <ButtonWithIconLeft
                fullWidth
                allowPropagation
                label="Asistencia Técnica"
                svgIcon={IconoQ}
                iconColor="#4298b5"
                onClick={() => {
                  navigate(getNavigatePath(hasModule(ModuleName.ASISTENCIA_TECNICA) || false, 'asistencia'));
                }}
                rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.ASISTENCIA_TECNICA) || false)}
              />
            )}
            {!isFounder && (
              <ButtonWithIconLeft
                fullWidth
                allowPropagation
                label="Mi cuenta"
                icon="ion:cart"
                iconColor="#4298b5"
                onClick={() => {
                  navigate('mi-cuenta');
                }}
              />
            )}
            {(isFreemium || hasModule(ModuleName.AJUSTES)) && (
              <ButtonWithIconLeft
                fullWidth
                allowPropagation
                label="Ajustes"
                icon="fa6-solid:gear"
                iconColor="#4298b5"
                onClick={() => {
                  navigate(getNavigatePath(hasModule(ModuleName.AJUSTES) || false, 'ajustes'));
                }}
                rightIcon={getNotIncludedOnCurrentPlanIcon(hasModule(ModuleName.AJUSTES) || false)}
              />
            )}
            <div className="flex w-full justify-center">
              <ButtonAction
                colorBg="color-warning"
                colorTxt="color-white"
                textButton="Cerrar Sesión"
                onClick={auth.logout}
              />
            </div>
          </div>
        </div>
      )}
      <div className="fixed w-full z-50 bg-color-primary flex justify-between text-color-white h-16 items-center p-4 lg:px-8">
        <div className="flex items-baseline">
          <img src={logoPlick} alt="logo plick" height="25px" />
          {isDesktop && (
            <>
              <span className="ml-2 mr-4">
                Pedidos a un <strong className="bg-color-secondary rounded-sm px-1">click.</strong>
              </span>
              <a className="mx-2" target="_blank" href="https://www.facebook.com/plickmx" rel="noreferrer">
                <img src={img_facebook} alt="Facebook" height="25px" />
              </a>
              <a className="mx-2" target="_blank" href="https://www.instagram.com/plickmx/?hl=es" rel="noreferrer">
                <img src={img_instagram} alt="Instagram" height="25px" />
              </a>
            </>
          )}
        </div>
        <div className="flex gap-4">
          <div className="flex gap-6">
            {orderLimits?.currentMonthCount !== undefined &&
            orderLimits?.monthlyLimit !== undefined &&
            orderLimits?.quotaUsagePercent !== undefined ? (
              <OrdersCounter
                isDesktop={isDesktop}
                current={orderLimits.currentMonthCount}
                limit={orderLimits.monthlyLimit}
                usagePercent={orderLimits.quotaUsagePercent}
              />
            ) : null}
            {!isDesktop && auth.isReady && (
              <button className="w-7 h-full flex flex-col justify-around" onClick={switchOpen}>
                <div className="h-1 w-full bg-color-white rounded-full"></div>
                <div className="h-1 w-full bg-color-white rounded-full"></div>
                <div className="h-1 w-full bg-color-white rounded-full"></div>
              </button>
            )}
          </div>
          {isDesktop ? (
            <div className="relative" ref={buttonRef}>
              <button
                className="outline-none bg-color-white w-[41px] h-[41px] rounded-[6px] text-color-primary flex items-center justify-center"
                onClick={() => setIsNewMenuOpen((prev) => !prev)}
              >
                <Tabler.IconDotsVertical width={22} height={22} />
              </button>
              {isNewMenuOpen ? (
                <ul
                  className="absolute bg-color-white rounded-[6px] text-color-primary right-0 w-max"
                  style={{ boxShadow: '0px 1px 4px 0px #00000066' }}
                >
                  {newMenuItems.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-center gap-2 py-[8px] hover:bg-color-gray_light px-[10px] rounded-[6px] hover:cursor-pointer"
                      onClick={() => {
                        setIsNewMenuOpen(false);
                        item.onClick();
                      }}
                      style={{ color: item.color }}
                    >
                      {item.icon}
                      <span className="flex items-center">{item.label}</span>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Header;
