/* eslint-disable complexity */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import type { BillingInformation, Commerce, TagInfo, GigstackPortal } from '@interfaces/general';
import { useFetchCallback } from '@services/services';
import {
  MSG_GET_BILLING_ERROR,
  MSG_GET_ERROR,
  MSG_GET_GIGSTACK_ERROR,
  MSG_UPDATE_ERROR,
  MSG_UPDATE_OK,
} from '@pages/Commerce/messages';
import { DELIVERY_TYPE_MAP, ModuleName } from '@utils/constants';
import { MissingKeysOf } from '@interfaces/utils';
import { useCommerceActions, useCommerceFromStore } from '@store/actions/commerce';
import { countries } from '@utils/countries';
import useModules from '@utils/hooks/useModules';
import useTracking, { TRACK_EVENT_NAMES } from '@utils/hooks/useTracking';
import * as Sentry from '@sentry/react';

export type MissingCommerceKeys =
  | MissingKeysOf<Commerce>
  | `informacion_envio.${MissingKeysOf<Commerce['informacion_envio']>}`
  | `costo_por_porcentaje.${MissingKeysOf<Commerce['costo_por_porcentaje']>}`
  | `bank_transfer_details.${MissingKeysOf<Commerce['bank_transfer_details']>}`
  | `address.${MissingKeysOf<Commerce['address']>}`;

export const useGetCommerce = () => {
  const commerce = useCommerceFromStore();
  const { updateCommerce } = useCommerceActions();
  const { isFetching, call: fetchCommerce } = useFetchCallback<Commerce>('get', '/v1/comercio');
  const { hasModule } = useModules();
  const hasDeliveryByKm = hasModule(ModuleName.ENVIO_POR_KM);
  const hasDeliveryProviders = hasModule(ModuleName.PROVEEDORES_ENTREGA);

  useEffect(() => {
    if (!commerce.id) {
      fetchCommerce()
        .then((response) => {
          let newCommerce: Partial<Commerce> = response;
          if (!response.config_form_entrega) {
            if (hasDeliveryByKm && newCommerce.tipo_envio_domicilio === DELIVERY_TYPE_MAP.por_kilometro.key) {
              newCommerce = {
                ...newCommerce,
                config_form_entrega: {
                  calle: { required: false, show: false },
                  colonia: { required: false, show: false },
                  no_ext: { required: false, show: false },
                  no_int: { required: false, show: false },
                  ubicacion: { required: true, show: true },
                  referencias: { required: true, show: true, label: '' },
                },
              };
            } else if (
              hasDeliveryProviders &&
              newCommerce.tipo_envio_domicilio === DELIVERY_TYPE_MAP.delivery_providers.key
            ) {
              newCommerce = {
                ...newCommerce,
                config_form_entrega: {
                  calle: { required: false, show: false },
                  colonia: { required: false, show: false },
                  no_ext: { required: false, show: false },
                  no_int: { required: false, show: false },
                  ubicacion: { required: true, show: true },
                  referencias: { required: false, show: false, label: '' },
                },
              };
            } else {
              delete newCommerce.config_form_entrega;
            }
          }
          if (newCommerce.onboarding_finish_date)
            localStorage.setItem('onboarding_finish_date', newCommerce.onboarding_finish_date);
          updateCommerce(newCommerce);
        })
        .catch(() => {
          toast.error(MSG_GET_ERROR);
        });
    }
  }, []);

  return { isFetching, commerce };
};

export const useEditCommerce = () => {
  const { commerce, isFetching } = useGetCommerce();
  const { updateCommerce } = useCommerceActions();
  const { isFetching: isUpdating, call: callUpdate } = useFetchCallback<Commerce, Commerce>('put', '/v1/comercio');

  const handleUpdate = async (item: Commerce) => {
    try {
      await callUpdate({ data: item });
      updateCommerce({ ...item });
      toast.success(MSG_UPDATE_OK);
    } catch (err) {
      toast.error(MSG_UPDATE_ERROR);
    }
  };
  return { commerce, isFetching: isUpdating || isFetching, handleUpdate };
};

type CommerceDataValidator = (commerce: Commerce) => MissingCommerceKeys[];

export const validateCommerceDataValues: CommerceDataValidator = (commerce: Commerce) => {
  const missingValues: (keyof Commerce)[] = [];
  if (!commerce.nombre || commerce.nombre.length < 5 || commerce.nombre.length > 35) missingValues.push('nombre');
  if (!commerce.telefono) missingValues.push('telefono');
  if (!commerce.country_code) missingValues.push('country_code');
  const [country] = countries.filter((item) => item.countryCode === commerce.country_code);

  if (commerce.telefono?.toString().length !== country.phoneLength) missingValues.push('telefono');
  if (!commerce.direccion) missingValues.push('direccion');
  return missingValues;
};

export const validateDeliveryCommerceValues: CommerceDataValidator = (commerce: Commerce) => {
  const missingValues: MissingCommerceKeys[] = [];
  if (!commerce.activar_envio_domicilio && !commerce.activar_comer_restaurant && !commerce.activar_pasar_recoger) {
    missingValues.push('activar_envio_domicilio');
    missingValues.push('activar_comer_restaurant');
    missingValues.push('activar_pasar_recoger');
  }
  if (
    commerce.activar_envio_domicilio &&
    (!commerce.metodos_pago_envio_domicilio ||
      !Object.keys(commerce.metodos_pago_envio_domicilio).some(
        (key) => commerce.metodos_pago_envio_domicilio[key as keyof typeof commerce.metodos_pago_envio_domicilio],
      ))
  )
    missingValues.push('metodos_pago_envio_domicilio');
  if (
    commerce.activar_comer_restaurant &&
    (!commerce.metodos_pago_comer_restaurant ||
      !Object.keys(commerce.metodos_pago_comer_restaurant).some(
        (key) => commerce.metodos_pago_comer_restaurant[key as keyof typeof commerce.metodos_pago_comer_restaurant],
      ))
  )
    missingValues.push('metodos_pago_comer_restaurant');
  if (
    commerce.activar_pasar_recoger &&
    (!commerce.metodos_pago_pasar_recoger ||
      !Object.keys(commerce.metodos_pago_pasar_recoger).some(
        (key) => commerce.metodos_pago_pasar_recoger[key as keyof typeof commerce.metodos_pago_pasar_recoger],
      ))
  )
    missingValues.push('metodos_pago_pasar_recoger');
  if (commerce.tipo_envio_domicilio === DELIVERY_TYPE_MAP.estandar.key && !commerce.costo_envio_domicilio)
    missingValues.push('costo_envio_domicilio');
  if (
    commerce.tipo_envio_domicilio === DELIVERY_TYPE_MAP.por_colonia.key &&
    (!commerce.colonias ||
      !commerce.colonias.length ||
      commerce.colonias.some((col) => typeof col.costo !== 'number' || !col.nombre))
  )
    missingValues.push('colonias');
  if (commerce.activar_envio_gratis_compra_minima && !commerce.importe_envio_gratis_compra_minima)
    missingValues.push('importe_envio_gratis_compra_minima');

  if (commerce.compra_minima_envio_domicilio === '') missingValues.push('compra_minima_envio_domicilio');

  if (commerce.tipo_envio_domicilio === DELIVERY_TYPE_MAP.por_kilometro.key) {
    if (!commerce.informacion_envio?.costo_km || commerce.informacion_envio?.costo_km < 1) {
      missingValues.push('informacion_envio');
      missingValues.push('informacion_envio.costo_km');
    }
    if (
      (!commerce.informacion_envio?.min_km && commerce.informacion_envio?.min_km !== 0) ||
      commerce.informacion_envio?.min_km < 0
    ) {
      missingValues.push('informacion_envio');
      missingValues.push('informacion_envio.min_km');
    }
    if (!commerce.informacion_envio?.max_km || commerce.informacion_envio?.max_km < 1) {
      missingValues.push('informacion_envio');
      missingValues.push('informacion_envio.max_km');
    }
    if (
      (!commerce.costo_envio_domicilio && commerce.costo_envio_domicilio !== 0) ||
      commerce.costo_envio_domicilio < 0
    ) {
      missingValues.push('costo_envio_domicilio');
    }
  }

  if (commerce.tipo_envio_domicilio === DELIVERY_TYPE_MAP.percent.key) {
    if (!commerce.costo_por_porcentaje?.cantidad) {
      missingValues.push('costo_por_porcentaje');
    }
    if (commerce.costo_por_porcentaje?.cantidad && commerce?.costo_por_porcentaje?.cantidad > 100) {
      missingValues.push('costo_por_porcentaje');
    }
    if (commerce.costo_por_porcentaje?.cantidad && commerce?.costo_por_porcentaje?.cantidad < 1) {
      missingValues.push('costo_por_porcentaje');
    }
  }

  if (
    commerce.metodos_pago_pasar_recoger.transferencia ||
    commerce.metodos_pago_comer_restaurant.transferencia ||
    commerce.metodos_pago_envio_domicilio.transferencia
  ) {
    if (commerce.bank_transfer_details?.titular_name) {
      if (!commerce.bank_transfer_details?.clabe || commerce.bank_transfer_details?.clabe.length !== 18) {
        missingValues.push('bank_transfer_details');
        missingValues.push('bank_transfer_details.clabe');
      }
      if (!commerce.bank_transfer_details?.bank_name) {
        missingValues.push('bank_transfer_details');
        missingValues.push('bank_transfer_details.bank_name');
      }
    } else if (commerce.bank_transfer_details?.clabe) {
      if (!commerce.bank_transfer_details?.bank_name) {
        missingValues.push('bank_transfer_details');
        missingValues.push('bank_transfer_details.bank_name');
      }
      if (!commerce.bank_transfer_details?.titular_name) {
        missingValues.push('bank_transfer_details');
        missingValues.push('bank_transfer_details.titular_name');
      }
      if (commerce.bank_transfer_details?.clabe.length !== 18) {
        missingValues.push('bank_transfer_details');
        missingValues.push('bank_transfer_details.clabe');
      }
    } else if (commerce.bank_transfer_details?.bank_name) {
      if (!commerce.bank_transfer_details?.clabe || commerce.bank_transfer_details?.clabe.length !== 18) {
        missingValues.push('bank_transfer_details');
        missingValues.push('bank_transfer_details.clabe');
      }
      if (!commerce.bank_transfer_details?.titular_name) {
        missingValues.push('bank_transfer_details');
        missingValues.push('bank_transfer_details.titular_name');
      }
    }
  }

  return missingValues;
};

export const validateAddressValues: CommerceDataValidator = (commerce: Commerce) => {
  const missingValues: MissingCommerceKeys[] = [];
  if (!commerce.address?.city) missingValues.push('address.city');
  if (!commerce.address?.state) missingValues.push('address.state');
  if (!commerce.address?.postalCode) missingValues.push('address.postalCode');
  if (!commerce.address?.exteriorNumber) missingValues.push('address.exteriorNumber');
  if (!commerce.address?.street) missingValues.push('address.street');
  if (!commerce.address?.fullAddress) missingValues.push('address.fullAddress');
  return missingValues;
};

export const useCommerce = (validator?: CommerceDataValidator) => {
  const { isFetching } = useGetCommerce();
  const cachedCom = useCommerceFromStore();
  const { updateCommerce } = useCommerceActions();
  const [dirtyKeys, setDirtyKeys] = useState<Set<keyof Commerce>>(new Set());
  const [missingValues, setMissingValues] = useState<MissingCommerceKeys[]>([]);
  const [commerce, setCommerce] = useState(cachedCom);

  useEffect(() => {
    if (cachedCom)
      setCommerce((currentCommerce) => {
        const newCommerce = { ...cachedCom };
        dirtyKeys.forEach((key) => {
          (newCommerce[key] as Commerce[keyof Commerce]) = currentCommerce[key];
        });
        return newCommerce;
      });
  }, [cachedCom]);

  const { isFetching: isUpdating, call: callUpdate } = useFetchCallback<Commerce, Commerce>('put', '/v1/comercio');

  const handleUpdateValue = <K extends keyof Commerce>(key: K, value: Commerce[K]) => {
    setDirtyKeys((currentSet) => currentSet.add(key));
    setCommerce((oldCommerce) => {
      const newCommerce = { ...oldCommerce, [key]: value };
      if (key === 'activar_pago_enlace' && !value) {
        newCommerce.metodos_pago_comer_restaurant = {
          ...newCommerce.metodos_pago_comer_restaurant,
          enlace_pago: false,
        };
        newCommerce.metodos_pago_envio_domicilio = {
          ...newCommerce.metodos_pago_envio_domicilio,
          enlace_pago: false,
        };
        newCommerce.metodos_pago_pasar_recoger = {
          ...newCommerce.metodos_pago_pasar_recoger,
          enlace_pago: false,
        };
      }
      if (validator && missingValues.includes(key)) setMissingValues(validator(newCommerce));
      return newCommerce;
    });
  };

  const handleSubmitChanges = async () => {
    if (validator) {
      const values = validator(commerce);
      if (values.length) {
        setMissingValues(values);
        throw new Error('Missing values');
      }
    } else if (!dirtyKeys.size) throw new Error('No changes to submit');

    if (
      dirtyKeys.has('config_form_entrega') &&
      Object.values(commerce.config_form_entrega).every((item) => !item.show)
    ) {
      toast.error('Al menos un valor debe estar habilitado');
      return;
    }

    const newCommerce = { ...commerce };
    if (dirtyKeys.has('tipo_envio_domicilio')) {
      if (commerce.tipo_envio_domicilio === DELIVERY_TYPE_MAP.por_kilometro.key) {
        newCommerce.config_form_entrega = {
          ...commerce.config_form_entrega,
          ubicacion: { required: true, show: true },
        };
      } else if (commerce.tipo_envio_domicilio === DELIVERY_TYPE_MAP.por_colonia.key) {
        newCommerce.config_form_entrega = {
          ...commerce.config_form_entrega,
          colonia: { required: true, show: true },
        };
      }
    }
    if (dirtyKeys.has('bank_transfer_details') && !commerce.bank_transfer_details?.clabe) {
      newCommerce.bank_transfer_details = null;
    }

    try {
      await callUpdate({ data: newCommerce });
      updateCommerce({ ...newCommerce });
      setDirtyKeys(new Set());
      toast.success(MSG_UPDATE_OK);
    } catch (err) {
      toast.error(MSG_UPDATE_ERROR);
      throw err;
    }
  };

  return {
    commerce,
    isDirty: dirtyKeys.size > 0,
    isUpdating,
    isFetching,
    missingValues,
    handleUpdateValue,
    handleSubmitChanges,
  };
};

export const useGetTags = () => {
  const [tags, setTags] = useState<TagInfo[]>([]);
  const { isFetching, call: fetchTags } = useFetchCallback<TagInfo[]>('get', '/v1/tag');
  useEffect(() => {
    fetchTags().then((response) => {
      if (response) setTags([...response]);
    });
  }, []);
  return { isFetching, tags };
};

export const useGetTimezones = () => {
  const [timeZones, setTimeZones] = useState<{ label: string; value: string }[]>([]);
  const { isFetching, call: fetchTimeZones } = useFetchCallback<{ label: string; value: string }[]>(
    'get',
    '/v1/public/timezone',
  );
  useEffect(() => {
    fetchTimeZones().then((response) => {
      if (response) setTimeZones([...response]);
    });
  }, []);
  return { isFetching, timeZones };
};

export const useBillingConfig = () => {
  const { isFetching, call } = useFetchCallback<BillingInformation>('get', 'v1/account/billing');
  const { trackEvent } = useTracking();

  const handleBillingRequest = async () => {
    try {
      trackEvent(TRACK_EVENT_NAMES.BillingModificationRequested);
      const response = await call();
      window.open(response.stripe_customer_portal, '_self');
    } catch (error) {
      toast.error(MSG_GET_BILLING_ERROR);
    }
  };
  return { isFetching, handleBillingRequest };
};

export const useGigStackPortal = () => {
  const { isFetching, call } = useFetchCallback<GigstackPortal>('get', 'v1/account/gigstackportal');
  const { trackEvent } = useTracking();

  const handleGigStackPortalRequest = async () => {
    try {
      trackEvent(TRACK_EVENT_NAMES.GigstackPortalUrlRequested);
      const response = await call();
      window.open(response.signedUrl, '_self');
    } catch (error) {
      Sentry.captureMessage(MSG_GET_GIGSTACK_ERROR);
      Sentry.captureException(error);
      toast.error(MSG_GET_GIGSTACK_ERROR);
    }
  };

  return {
    isFetching,
    handleGigStackPortalRequest,
  };
};
