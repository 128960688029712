import { AvailableSubscription, Subscription } from '@interfaces/general';
import { useFetchCallback } from '@services/services';
import {
  useAvailableSubscriptionsActions,
  useAvailableSubscriptionsFromStore,
} from '@store/actions/availableSubscriptions';
import { useSubscriptionActions, useSubscriptionFromStore } from '@store/actions/subscription';
import { SUBSCRIPTION_TYPES_MAP } from '@utils/constants';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

export const useInitSubscriptionDetails = () => {
  const { call } = useFetchCallback<Subscription>('get', '/v1/auth/account-details');
  const { initSubscription } = useSubscriptionActions();

  useEffect(() => {
    call()
      .then(initSubscription)
      .catch((err) => {
        toast.error(err?.message || 'Error al obtener los detalles de la suscripción activa');
      });
  }, []);
};

export const useSubscription = () => {
  const {
    planId,
    trialDaysRemaining,
    trialUsed,
    modulesAndPermissions,
    accountCommerceTokens,
    accountId,
    lastSuscriptionInvoice,
    orderLimits,
  } = useSubscriptionFromStore();
  return {
    planId: planId as keyof typeof SUBSCRIPTION_TYPES_MAP,
    isFreemium: planId === SUBSCRIPTION_TYPES_MAP.FREEMIUM.key,
    isTrial: planId === SUBSCRIPTION_TYPES_MAP.TRIAL.key,
    isFounder: planId === SUBSCRIPTION_TYPES_MAP.FUNDADOR.key,
    remainingTrialDays: trialDaysRemaining,
    isTrialAvailable: !trialUsed,
    trialUsed,
    modulesAndPermissions,
    accountCommerceTokens,
    accountId,
    lastSuscriptionInvoice,
    orderLimits,
  };
};

export const useAvailableSubscriptions = () => {
  const subscriptions = useAvailableSubscriptionsFromStore();
  const { initSubscriptions } = useAvailableSubscriptionsActions();
  const { call } = useFetchCallback<AvailableSubscription[]>('get', '/v1/planes');

  useEffect(() => {
    if (!subscriptions || !subscriptions.length) {
      call()
        .then(initSubscriptions)
        .catch(() => toast.error('Error al obtener los planes disponibles'));
    }
  }, []);

  const getSubscriptionById = (id: keyof typeof SUBSCRIPTION_TYPES_MAP) => {
    return subscriptions.find((subscription) => subscription.id === id);
  };

  return {
    subscriptions,
    getSubscriptionById,
  };
};

export const useEnableTrial = () => {
  const { call, isFetching } = useFetchCallback<AvailableSubscription[]>('put', '/v1/suscriptions/activate-trial');

  const enableTrial = () => {
    call()
      .then(() => window.location.assign('/'))
      .catch((err) => {
        toast.error(err?.message || 'Error al activar el periodo de prueba');
      });
  };

  return {
    enableTrial,
    isEnablingTrial: isFetching,
  };
};
