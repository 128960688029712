import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import useStoredState from '@utils/hooks/useStoredState';
import { EMPTY_VALUE, TELEMETRY_STORAGE_KEY } from '@utils/constants';

type EventData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const TRACK_EVENT_NAMES = {
  CouponsSearchLoaded: 'Cupones - Resultados de Búsqueda Cargados',
  CouponSearchClicked: 'Cupones - Búsqueda Realizada',
  CouponAddClicked: 'Cupones - Clic en Botón Agregar',
  CouponEditClicked: 'Cupones - Clic en Botón Editar',
  CouponCopyClicked: 'Cupones - Clic en Botón Copiar',
  CouponDeleteClicked: 'Cupones - Clic en Botón Eliminar',
  RegistrationCommerceDataUpdated: 'Registro - Datos del comercio',
  RegistrationContactDataUpdated: 'Registro - Datos del contacto',
  RegistrationAccessDataUserUpdated: 'Registro - Datos de acceso del usuario',
  RegistrationAccessDataPasswordUpdated: 'Registro - Datos de acceso de contraseña',
  RegistrationFormSubmitted: 'Registro - Formulario enviado',
  LocationEditClicked: 'Configuración - Clic en Editar Ubicación',
  LocationSaveClicked: 'Configuración - Clic en Guardar Ubicación',
  LocationSaveError: 'Configuración - Error al Guardar Ubicación',
  IntegrationUnavailableConnectClick: 'Integraciones - Clic en Integracion no disponible',
  MenuOrderPhoneOpened: 'Ordenes - Menu embebido abierto',
  MenuOrderPhoneClosed: 'Ordenes - Menu embebido cerrado',
  MenuOrderPhoneNewOrder: 'Ordenes - Menu embebido pedido recibido',
  ConfigurationMenuThemeSelection: 'Configuracion - Tema de menu seleccionado',
  ConfigurationMenuThemeChangeDenied: 'Configuracion - Tema no disponible para plan',
  BillingModificationRequested: 'Facturación Stripe - Solicitud de modificación',
  GigstackPortalUrlRequested: 'Gigstack - Portal de facturación',
  OnboardingPageLoaded: 'Onboarding - Cargado',
  OnboardingGeneralInfoSaved: 'Onboarding - Información General Guardada',
  OnboardingCommerceAddressSaved: 'Onboarding - Dirección del Comercio Guardada',
  OnboardingSchedulesSaved: 'Onboarding - Horarios Guardados',
  OnboardingDeliveryMethodsSaved: 'Onboarding - Métodos de Entrega Guardados',
  OnboardingFinished: 'Onboarding - Completado',
};

export const TRACK_ERROR_NAMES = {
  StatsIframeLoading: 'Error - Iframe Metabase',
};

const getLoginData = () => {
  return {
    commerceId: localStorage.getItem('id_commerce'),
    id: localStorage.getItem('id_user'),
    pathId: localStorage.getItem('id_path'),
    commerceName: localStorage.getItem('commerce_name'),
    commerceLogo: localStorage.getItem('commerce_logo'),
    userName: localStorage.getItem('user_name'),
    userPermissions: localStorage.getItem('user_permissions'),
    userData: localStorage.getItem('userData') ?? '',
  };
};

const defaultEventData = () => {
  const loginData = getLoginData();
  const user = loginData.userData ? JSON.parse(loginData.userData) : null;

  return {
    ...loginData,
    ...(user && {
      user: {
        id: user.id,
        email: user.email,
        name: user.name,
        status: user.status,
        username: user.username,
      },
    }),
  };
};

const useTracking = () => {
  const posthog = usePostHog();
  const [telemetrySessionId, setTelemetrySessionId] = useStoredState(TELEMETRY_STORAGE_KEY, EMPTY_VALUE);

  useEffect(() => {
    if (!telemetrySessionId) {
      const newSessionId = posthog?.get_session_id();
      setTelemetrySessionId(newSessionId);
    }

    posthog.capture('$pageview');
    return () => {
      posthog.capture('$pageleave');
    };
  }, []);

  const trackEvent = (eventName: string, eventData?: EventData) => {
    const defaultData = defaultEventData();
    const data = {
      ...defaultData,
      ...eventData,
    };

    if (defaultData.user) {
      data.distinct_id = defaultData.commerceName;
      data.id_commerce = defaultData.commerceId;
      data.userId = defaultData.user.id;
      data.userEmail = defaultData.user.email;
      data.userName = defaultData.user.username;
    }

    posthog.capture(eventName, data);
  };

  return {
    trackEvent,
  };
};

export default useTracking;
